import styled from "@emotion/styled"

import Loader from "@components/Loader"

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.colors.backgroundColor};
`

const PageLoader = () => (
    <Wrapper>
        <Loader type='Oval' width={50} height={50} />
    </Wrapper>
)

export default PageLoader
