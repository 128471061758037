import { createApi } from "@reduxjs/toolkit/query/react"
import fetchBaseQuery from "./baseQuery"

const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: fetchBaseQuery,
    endpoints: (builder) => ({
        getAllPrices: builder.query<PriceApi[], void>({
            query: () => ({
                method: "GET",
                url: "/price"
            })
        })
    })
})

export const { useGetAllPricesQuery } = priceApi
export default priceApi
