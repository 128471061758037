// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from "redux"

import coinApi from "@services/coin"
import priceApi from "@services/price"
import feeApi from "@services/fee"
import profileApi from "@services/profile"
import userPreferencesSlicer from "./user-preferences/slicer"

const rootReducers = combineReducers({
    [userPreferencesSlicer.name]: userPreferencesSlicer.reducer,
    [coinApi.reducerPath]: coinApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [feeApi.reducerPath]: feeApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer
})

export default rootReducers
