import { Theme } from "@emotion/react"

const darkTheme: Theme = {
    colors: {
        text: {
            base: "#ffffff",
            muted: "#ffffff80"
        },
        backgroundColor: "#171717",
        notificationBarColor: "#1d1d1d",
        borderColor: "#222222",
        semantic: {
            primary: {
                100: "#D0EEFE",
                200: "#A2D8FD",
                300: "#73BEFA",
                400: "#50A4F6",
                500: "#197CF1",
                600: "#1260CF",
                700: "#0C47AD",
                800: "#07328B",
                900: "#042373"
            },
            success: {
                100: "#CBFCCF",
                200: "#98F9AB",
                300: "#62EE8B",
                400: "#3BDD7B",
                500: "#05C765",
                600: "#03AB67",
                700: "#028F64",
                800: "#01735C",
                900: "#005F55"
            }
        }
    },
    components: {
        ctaSection: {
            backgroundColor: "#191919",
            downloadButton: {
                textColor: "#ffffff",
                backgroundColor: "#454545",
                backgroundColorHover: "#626262"
            }
        },
        skeleton: {
            baseColor: "#888888",
            highlightColor: "#757575"
        }
    }
}

export default darkTheme
