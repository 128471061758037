import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { DEFAULT_LANG } from "@config/index"
import TranslationEN from "./locales/en/translation.json"
import TranslationID from "./locales/id/translation.json"

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: TranslationEN
        },
        id: {
            translation: TranslationID
        }
    },
    lng: localStorage.getItem("lang") || DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    interpolation: {
        escapeValue: false
    }
})

export default i18n
