import { createApi } from "@reduxjs/toolkit/query/react"
import fetchBaseQuery from "./baseQuery"

const coinApi = createApi({
    reducerPath: "coinApi",
    baseQuery: fetchBaseQuery,
    endpoints: (builder) => ({
        getCoins: builder.query<CoinsApi, void>({
            query: () => ({
                method: "GET",
                url: "/coins"
            })
        })
    })
})

export const { useGetCoinsQuery } = coinApi
export default coinApi
