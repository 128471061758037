import { css } from "@emotion/react"
import { PUBLIC_URL } from "@config/index"

const fontsCss = css`
    @font-face {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        src: local(""), url("${PUBLIC_URL}/assets/fonts/TitilliumWeb/TitilliumWeb-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 600;
        src: local(""), url("${PUBLIC_URL}/assets/fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 700;
        src: local(""), url("${PUBLIC_URL}/assets/fonts/TitilliumWeb/TitilliumWeb-Bold.ttf") format("truetype");
    }

    body,
    #core-root {
        * {
            font-family: "Titillium Web", sans-serif !important;
            -webkit-font-smoothing: auto;
            -moz-osx-font-smoothing: auto;
        }
    }
`

export default fontsCss
