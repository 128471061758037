import React from "react"
import { CacheProvider, Global, ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import createCache from "@emotion/cache"

import useUserPreferences from "@hooks/useUserPreferences"
import globalStyles from "./globals"
import lightTheme from "./themes/light"
import darkTheme from "./themes/dark"

interface ThemeProviderProps extends React.PropsWithChildren<{}> {}

const emotionCache = createCache({
    key: "reku",
    speedy: false
})

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }: ThemeProviderProps) => {
    const {
        data: { darkMode }
    } = useUserPreferences()

    return (
        <>
            <Global styles={globalStyles} />
            <CacheProvider value={emotionCache}>
                <EmotionThemeProvider theme={!darkMode ? lightTheme : darkTheme}>{children}</EmotionThemeProvider>
            </CacheProvider>
        </>
    )
}

export default ThemeProvider
