/* eslint-disable no-underscore-dangle */
import { Suspense } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import PageLoader from "@components/PageLoader"
import { PUBLIC_URL } from "./config"
import routes from "./routes/routes"
import ThemeProvider from "./styles/provider"
import ReduxProvider from "./redux/provider"
import "@i18n/index"

const App = () => {
    const renderedRoutes = routes.map(({ path, element: Component }) => <Route key={path} path={path} element={<Component />} />)

    return (
        <div id='core-root'>
            <ReduxProvider>
                <ThemeProvider>
                    <BrowserRouter basename={typeof window.__POWERED_BY_QIANKUN__ === "undefined" ? PUBLIC_URL : undefined}>
                        <Suspense fallback={<PageLoader />}>
                            <Routes>{renderedRoutes}</Routes>
                        </Suspense>
                    </BrowserRouter>
                </ThemeProvider>
            </ReduxProvider>
        </div>
    )
}

export default App
