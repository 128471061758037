import { css } from "@emotion/react"

import sanitizeCss from "./sanitize"
import fontsCss from "./fonts"

const globalStyles = css`
    ${sanitizeCss};
    ${fontsCss};

    * {
        box-sizing: border-box;
    }

    html {
        line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
    }
`

export default globalStyles
