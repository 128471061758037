import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@redux/hooks"

import { changeUserPreference } from "@redux/user-preferences/slicer"
import { getCookie } from "@helpers/cookies"
import { store } from "@redux/store"

const useUserPreferences = () => {
    const data = useAppSelector((state) => state.userPreferences)
    const dispatch = useAppDispatch()

    const update = (preferences: UserPreferencesModel) => {
        dispatch(changeUserPreference(preferences))
    }

    useEffect(() => {
        const cookie = getCookie("reku-darkmode")
        const darkMode = Boolean(Number(cookie || 0))

        update({ darkMode })
    }, [])

    return {
        data,
        update
    }
}

export const setUserPreferences = (preferences: UserPreferencesModel) => {
    store.dispatch(changeUserPreference(preferences))
}

export default useUserPreferences
