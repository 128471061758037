import { createApi } from "@reduxjs/toolkit/query/react"
import fetchBaseQuery from "./baseQuery"

const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery,
    endpoints: (builder) => ({
        getProfile: builder.query<ProfileAPI, void>({
            query: () => ({
                method: "GET",
                url: "/profile"
            })
        })
    })
})

export const { useGetProfileQuery } = profileApi
export default profileApi
