import { lazy } from "react"

const Home = lazy(() => import("../pages/Home"))
const Fees = lazy(() => import("../pages/Fees"))

const routes = [
    {
        path: "/",
        element: Home
    },
    {
        path: "/fees",
        element: Fees
    }
]

export default routes
