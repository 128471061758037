import { Cookies } from "react-cookie"

const cookie = new Cookies()

interface Options {
    path?: string
    maxAge?: number
    domain?: string
}

export const getCookie = (name: string, options?: Options) => cookie.get(name, options as any)

export const setCookie = (name: string, value: string, options?: Options) => cookie.set(name, value, options as any)
