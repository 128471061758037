import { createApi } from "@reduxjs/toolkit/query/react"
import fetchBaseQuery from "./baseQuery"

const feeApi = createApi({
    reducerPath: "feeApi",
    baseQuery: fetchBaseQuery,
    endpoints: (builder) => ({
        getFees: builder.query<FeesApi, void>({
            query: () => ({
                method: "GET",
                url: "/pages/fee"
            })
        })
    })
})

export const { useGetFeesQuery } = feeApi
export default feeApi
