import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const userPreferencesSlicer = createSlice({
    name: "userPreferences",
    initialState: {
        darkMode: false
    } as UserPreferencesModel,
    reducers: {
        changeUserPreference: (state, action: PayloadAction<UserPreferencesModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeUserPreference } = userPreferencesSlicer.actions
export default userPreferencesSlicer
