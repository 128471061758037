/* eslint-disable no-underscore-dangle */
import "./public-path"

import React from "react"
import ReactDOM from "react-dom"

import { setUserPreferences } from "@hooks/useUserPreferences"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { APP_ENV } from "./config"

interface RenderProps {
    container?: HTMLHtmlElement
    onGlobalStateChange?: (callback: (state: any, prev: any) => void) => void
}

const render = (props: RenderProps) => {
    props?.onGlobalStateChange?.((state, prev) => {
        if (state?.darkMode !== prev?.darkMode) {
            setUserPreferences({ darkMode: state?.darkMode })
        }
    })

    ReactDOM.render(<App />, props.container ? props.container.querySelector("#root") : document.getElementById("root"))
}

if (!window.__POWERED_BY_QIANKUN__) {
    if (APP_ENV === "DEVELOPMENT") {
        render({})
    }
}

export const bootstrap = async () => {}

export const mount = async (props: any) => {
    render(props)
}

export const unmount = async (props: any) => {
    ReactDOM.unmountComponentAtNode(props.container ? props.container.querySelector("#root") : document.getElementById("root"))
}

export const update = async () => {}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
