/* eslint-disable import/prefer-default-export */
import { APP_ENV } from "@config/index"
import { getCookie } from "./cookies"

const hashKey = APP_ENV?.toLocaleLowerCase() === "development" ? "devuhash" : "uhash"
const tokenKey = APP_ENV?.toLocaleLowerCase() === "development" ? "devrkhash" : "rkhash"

export const getAuth = () => {
    const uhash = getCookie(hashKey)
    const rkhash = getCookie(tokenKey)

    const checkAuth = (uhash && rkhash) || rkhash

    return {
        token: checkAuth ? rkhash : null,
        hash: checkAuth ? uhash : null,
        isLoggedIn: checkAuth
    }
}
