import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./store"

interface ReduxProviderProps extends React.PropsWithChildren<{}> {}

const ReduxProvider: React.FC<ReduxProviderProps> = ({ children }: ReduxProviderProps) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
)

export default ReduxProvider
