import { Theme } from "@emotion/react"

const lightTheme: Theme = {
    colors: {
        text: {
            base: "#333333",
            muted: "#33333380"
        },
        backgroundColor: "#ffffff",
        notificationBarColor: "#ffffff",
        borderColor: "#f2f2f5",
        semantic: {
            primary: {
                100: "#D0EEFE",
                200: "#A2D8FD",
                300: "#73BEFA",
                400: "#50A4F6",
                500: "#197CF1",
                600: "#1260CF",
                700: "#0C47AD",
                800: "#07328B",
                900: "#042373"
            },
            success: {
                100: "#D3FAE8",
                200: "#AAF6DA",
                300: "#7AE4C7",
                400: "#55C9B4",
                500: "#26a69a",
                600: "#1B8E8E",
                700: "#136E77",
                800: "#0C5160",
                900: "#073C4F"
            }
        }
    },
    components: {
        ctaSection: {
            backgroundColor: "#f6f8fa",
            downloadButton: {
                textColor: "#7f7f7f",
                backgroundColor: "#e5e5e5",
                backgroundColorHover: "#cccccc"
            }
        },
        skeleton: {
            baseColor: "#f3f3f3",
            highlightColor: "#ecebeb"
        }
    }
}

export default lightTheme
